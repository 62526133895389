<template>
  <div class="home active" > <!-- :class="navigationVisible && 'active'" -->
    <img class="home-grid" src="@/assets/img/grid.svg" />
    <div class="home-grid__top" > </div>
    <div class="home-grid__bot" > </div>
      <div class="home-main">
        <a class="home-main__link" href="https://www.delivery-club.ru/" target="_blank">
          <img class="home-main__logo" src="@/assets/img/home/logo.png" />
        </a>

        <div class="home-main__left">      
          
          <img src="@/assets/img/home/logo.png" class="home-main__left-title" />

          <div class="home-main__left__online"> <div class="home-main__left__online__block">Трансляция финала</div></div>
                     
         
          <div class="home-main__left-nav">
            <router-link class="home-main__left-nav__item" to="/dota2"><img src="@/assets/img/glich/item/5.gif" class="home-main__left-nav__item__left" /> <img src="@/assets/img/glich/item/6.gif" class="home-main__left-nav__item__right" /><span>Dota 2</span></router-link>
            <router-link class="home-main__left-nav__item" to="/warface"><img src="@/assets/img/glich/item/5.gif" class="home-main__left-nav__item__left" /> <img src="@/assets/img/glich/item/6.gif" class="home-main__left-nav__item__right" /><span>Warface</span></router-link>
            <router-link class="home-main__left-nav__item" to="/fifa"><img src="@/assets/img/glich/item/5.gif" class="home-main__left-nav__item__left" /> <img src="@/assets/img/glich/item/6.gif" class="home-main__left-nav__item__right" /><span>FIFA 21</span></router-link>            
          </div>
          <div class="home-main__left-desc">
            <img class="home-main__left-desc__back" src="@/assets/img/home/desc.png" />
            <img class="home-main__left-desc__back-mob" src="@/assets/img/home/desc-mob.png" />
            
            <div class="home-main__left-desc__title active">
              Смотрите финальные игры первого турнира <br/><span>Delivery Cup!</span> Финалисты встретятся в <br/>ожесточенной схватке уже <b>28 мая.</b><br/><br/><br/> Поддержите фаворитов в борьбе за ценные<br/> призы!
            </div>

            <div class="home-main__left-desc-soc">
            <!--  <a :href="`https://www.facebook.com/sharer.php?u=${publicPath}`" target="_blank" class="home-main__left-desc-soc-link">
                <img class="home-main__left-desc-soc-link__img" src="@/assets/img/home/soc/fb.svg" />
              </a>
              <a href="https://twitter.com/intent/tweet?text=Киберспортивный турнир для курьеров Delivery Cup Становитесь курьером Delivery Club и участвуйте в соревнованиях Dota 2, Warface и FIFA" target="_blank" class="home-main__left-desc-soc-link">
                <img class="home-main__left-desc-soc-link__img" src="@/assets/img/home/soc/twt.svg" />
              </a>
            -->
              <a :href="`https://vk.com/share.php?url=${publicPath}&title=Киберспортивный турнир для курьеров Delivery Cup Становитесь курьером Delivery Club и участвуйте в соревнованиях Dota 2, Warface и FIFA&image=${this.publicPath}/static/share2.jpg&noparse=true`" target="_blank" class="home-main__left-desc-soc-link">
                <img class="home-main__left-desc-soc-link__img" src="@/assets/img/home/soc/vk.svg" />
              </a>
              <a :href="`http://www.odnoklassniki.ru/dk?st.cmd=addShare&st.s=1&st._surl={${publicPath}}&st.comments={Киберспортивный турнир для курьеров Delivery Cup Становитесь курьером Delivery Club и участвуйте в соревнованиях Dota 2, Warface и FIFA}`" target="_blank" class="home-main__left-desc-soc-link">
                <img class="home-main__left-desc-soc-link__img" src="@/assets/img/home/soc/odn.svg" />
              </a>
          </div>
            
          </div>
          <a v-if="navigationVisible" href="#Form" class="home-main__left__kur">
            Ещё не курьер Delivery Club? <br />Станьте им и начните играть!
          </a>
        </div>

        <div v-if="false" class="home-main__center">
          <img :src="require('@/assets/img/home/center-'+gifCenter+'.gif')" class="home-main__center-img" />  
          <img src="@/assets/img/home/center.png" class="home-main__center__joy" />
        </div>             
        
        <div v-if="false" class="home-main__right" >  
          <img :src="require('@/assets/img/home/del-'+gifCenter+'.png')" class="home-main__right-img" />     
        </div>   

        <div class="home-main__right-two">  
          <iframe v-if="isVk" class="home-main__right-two__video" width="1920" height="532" src="https://www.youtube.com/embed/DAonG8_F49E" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <iframe v-else class="home-main__right-two__video" width="1920" height="532" src="https://vk.com/video_ext.php?oid=-17700656&id=456239508&hash=0d2db11ee11d96cd" frameborder="0" allowfullscreen></iframe>
          <div class="home-main__right-two__items">
            <div @click="isVk = true" class="home-main__right-two__items-item"> <div class="home-main__right-two__items-item__block"> <img class="home-main__right-two__items-item__block-img" src="@/assets/img/youtube.svg" /> </div> </div>
            <div @click="isVk = false" class="home-main__right-two__items-item"> <div class="home-main__right-two__items-item__block"> <img class="home-main__right-two__items-item__block-img" src="@/assets/img/vk.svg" />  </div> </div>
          </div>
        </div>
      </div>

      <div class="home-fixed" :class="(navigationVisible && modalTxt) && 'active'">
        <div v-if="modalTxt && navigationVisible" class="home-fixed__block">
          <div class="home-fixed__block__txt">
            Подпишитесь на <a href="https://discord.gg/2dZmRsDYEA" target="__blank">Discord-канал</a> турнира, <br class="br__desk"/>чтобы следить за новостями, найти команду <br class="br__desk"/>и делиться впечатлениями!
          </div>
          <a v-if="else" href="https://discord.gg/2dZmRsDYEA" target="_blank" class="home-fixed__block__link">
            <div class="home-fixed__block__link__block">Хочу в команду</div>            
          </a>
          <a :href="link" target="_blank" class="home-fixed__block__link green">
            <div class="home-fixed__block__link__block green">Следить за турниром!</div>
          </a>
        </div>
        <div class="home-fixed-menu">
          <div v-if="navigationVisible" class="home-fixed-menu__arrow" @click="()=>{modalTxt = !modalTxt}">
            <img v-if="modalTxt" class="home-fixed-menu__arrow__img" src="@/assets/img/home/arrow-off.svg" />
            <img v-else class="home-fixed-menu__arrow__img" src="@/assets/img/home/arrow.svg" />            
          </div>
          <div class="home-fixed-menu-soc">
            <a :href="`https://vk.com/share.php?url=${publicPath}&title=Киберспортивный турнир для курьеров Delivery Cup Становитесь курьером Delivery Club и участвуйте в соревнованиях Dota 2, Warface и FIFA&image=${this.publicPath}/static/share2.jpg&noparse=true`" target="_blank" class="home-fixed-menu-soc-link">
              <img class="home-fixed-menu-soc-link__img" src="@/assets/img/home/soc/vk.svg" />
            </a>
            <a :href="`http://www.odnoklassniki.ru/dk?st.cmd=addShare&st.s=1&st._surl={${publicPath}}&st.comments={Киберспортивный турнир для курьеров Delivery Cup Становитесь курьером Delivery Club и участвуйте в соревнованиях Dota 2, Warface и FIFA}`" target="_blank" class="home-fixed-menu-soc-link">
              <img class="home-fixed-menu-soc-link__img" src="@/assets/img/home/soc/odn.svg" />
            </a>
          </div>
        </div>
      </div>

      <img v-if="navigationVisible" src="@/assets/img/home/skip.png" class="home__skip" />
  </div>
</template>

<!-- 
  <a href="https://twitter.com/intent/tweet?text=Киберспортивный турнир для курьеров Delivery Cup Становитесь курьером Delivery Club и участвуйте в соревнованиях Dota 2, Warface и FIFA" target="_blank" class="home-fixed-menu-soc-link">
              <img class="home-fixed-menu-soc-link__img" src="@/assets/img/home/soc/twt.svg" />
            </a>
<a :href="`https://www.facebook.com/sharer.php?u=${publicPath}`" target="_blank" class="home-fixed-menu-soc-link">
              <img class="home-fixed-menu-soc-link__img" src="@/assets/img/home/soc/fb.svg" />
            </a> -->
<script>
import style from './style.scss';

export default {
  name: 'Home',
  props: ['dateDesc', 'dateVis', 'link'],
  data(){
    return{
      modalTxt: false,
      navigationVisible: false,
      gifCenter: '',
      isVk: true
    }
  },
  mounted(){
    this.$router.beforeEach((to, from, next) => {     
        if (to.matched.length && to.path !== '/') {
          this.navigationVisible = true;          
          this.gifCenter = to.name;
        }
        next()
      })
  },
  computed: {
    publicPath(){
      return window.location.origin
    }
  },
  components: {
    
  }
}
</script>
