import { createStore } from 'vuex'

export default createStore({
  state: {
    games: [
      {
        id: 0,
        route: 'Dota',
        name: 'Dota 2',
        prize: [['Геймерский комплект периферии','Сертификат на 10 000 рублей в Delivery Club'],['Игровые наушники','Сертификат на 5 000 <br class="br__desk" />рублей в Delivery Club'],['Игровая мышь','Сертификат на 3 000 <br class="br__desk" />рублей в Delivery Club']],
        linkTurn: 'https://pvp.gg/tournament/12403',
        videoAll: ['https://youtube.com/embed/IUYKQ7OM3wI','https://www.youtube.com/embed/-cSFPIwMEq4','https://www.youtube.com/embed/4QxqctzXQqc','https://www.youtube.com/embed/BkW_m33OEcE'],
        whyDesk: '92% курьеров Delivery Club любят игры. Почему не дать им <br />возможность попробовать себя в роли профессиональных <br />киберспортсменов? <br /><br />Мы выбрали их любимые дисциплины (WARFACE, DOTA 2 и FIFA) <br />и решили собрать полноценный турнир, финал которого <br />проведем в Москве: там ребята смогут познакомиться с <br />коллегами и увлекательно провести время вместе.',
        rules: "Смотрите финальные игры первого турнира Delivery Cup по <br/>DOTA 2! Финалисты встретятся в ожесточенной схватке уже <br/>28 мая. <br/><br/>Поддержите фаворитов в борьбе за ценные призы!",
        desc: 'DOTA 2 — это многопользовательская командная игра в жанре <br />battle arena, разработанная корпорацией Valve. Выбирайте <br />сторону, прокачивайте любимого персонажа, защищайте или <br />атакуйте крепость и ведите свою команду к победе!<br class="br__desk" /><br class="br__desk" /><br class="br__desk" /><br class="br__desk" />',
        },
      {
        id: 1,
        route: 'Warface',
        name: 'Warface',
        prize: [['Геймерский комплект периферии','Сертификат на 10 000 рублей в Delivery Club','50 000 кредитов на команду'],['Игровые наушники','Сертификат на 5 000 рублей <br class="br__desk" />в Delivery Club','40 000 кредитов на команду'],['Игровая мышь','Сертификат на 3 000 рублей <br class="br__desk" />в Delivery Club','30 000 кредитов на команду']],
        linkTurn: 'https://pvp.gg/tournament/12402',
        videoAll: ['https://youtube.com/embed/IUYKQ7OM3wI','https://www.youtube.com/embed/kSP8JqVXi20','https://www.youtube.com/embed/CVy-RLUyxDo','https://www.youtube.com/embed/_sod1hW9uW0'],
        whyDesk: '92% курьеров Delivery Club любят игры. Почему не дать им <br />возможность попробовать себя в роли профессиональных <br />киберспортсменов? <br /><br />Мы выбрали их любимые дисциплины (WARFACE, DOTA 2 и FIFA) <br />и решили собрать полноценный турнир, финал которого <br />проведем в Москве: там ребята смогут познакомиться с <br />коллегами и увлекательно провести время вместе.',
        rules: 'Смотрите финальные игры первого турнира Delivery Cup по <br/>Warface! Финалисты встретятся в ожесточенной схватке уже <br/>28 мая. <br/><br/>Поддержите фаворитов в борьбе за ценные призы!',
        desc: 'Многопользовательский онлайн-шутер нового поколения <br class="br__desk" />от создателей Far Cry <br class="br__mob" />и Crysis. Бои проходят по всему земному <br />шару — в современных мегаполисах <br class="br__desk" />и самых диких уголках. <br class="br__desk" />Для игры доступны PVE- и PVP-миссии: участвуйте в сражении <br />двух отрядов на разнообразных картах или же отправьтесь <br class="br__desk" />на миссию в одну из горячих точек, выполняя разнообразные <br />боевые задачи. Используйте сильные стороны своего класса <br class="br__desk" />и помните о командной игре, чтобы добиться победы!',
       },
      {
        id: 2,
        route: 'Fifa',
        name: 'FIFA 21',
        prize: [['Игровая приставка','Сертификат на 10 000 рублей в Delivery Club'],['Игровые наушники','Сертификат на 5 000 <br class="br__desk" />рублей в Delivery Club'],['Мощный пауэрбэнк','Сертификат на 3 000 <br class="br__desk" />рублей в Delivery Club']],
        linkTurn: 'https://pvp.gg/tournament/12401',
        videoAll: ['https://youtube.com/embed/IUYKQ7OM3wI','https://www.youtube.com/embed/vgQNOIhRsV4','https://www.youtube.com/embed/9E4HMgh7ZsE','https://www.youtube.com/embed/Y94Ay-B4T3E'],
        whyDesk: '92% курьеров Delivery Club любят игры. Почему не дать им <br />возможность попробовать себя в роли профессиональных <br />киберспортсменов? <br /><br />Мы выбрали их любимые дисциплины (WARFACE, DOTA 2 и FIFA) <br />и решили собрать полноценный турнир, финал которого <br />проведем в Москве: там ребята смогут познакомиться с <br />коллегами и увлекательно провести время вместе.',
        rules: 'Смотрите финальные игры первого турнира Delivery Cup <br/>по FIFA 2021! Финалисты встретятся в ожесточенной схатке уже <br/>28 мая. <br/><br/>Поддержите фаворитов в борьбе за ценные призы!',
        desc: 'FIFA 2021 — это многопользовательский футбольный симулятор, <br />разработанный компаниями EA Vancouver и EA Romania под <br />издательством Electronic Arts. Пишите свою историю футбола, <br />разрабатывайте тактику, нанимайте лучших игроков. <br />Прикоснитесь к мировому футболу у себя дома!<br class="br__desk" /><br class="br__desk" /><br class="br__desk" /><br class="br__desk" />',
      }
    ]
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
