<template>
  <Home :dateVis="navigationVisible" :link="linkComand" />
  <Navigation v-if="navigationVisible" />
  <router-view /> 
  <div class="glich">
    <img v-for="item in 12" :ref="'glich'+item" class="glich__img" :src="require(`@/assets/img/glich/${item}.svg`)" />
  </div>
</template>

<script>
import Home from "./views/Home";
import Navigation from "./views/Navigation";
import { ref, watchEffect } from 'vue'

export default {
  name: 'Main',
  data(){
    return {
      navigationVisible: false,
      homeDesc: '',
      linkComand: ''
    }
  },
  components: {
    Home, Navigation
  },  
  computed:{
    
  },
  methods: {
    randomInteger(min, max) {
      let rand = min + Math.random() * (max + 1 - min);
      return Math.floor(rand);
    },
    glichOff(e){
      setTimeout(()=>{
        e.style.opacity = 0;
      }, 1500)
    }
  },
  mounted(){      
      this.$router.beforeEach((to, from, next) => {     
        if (to.matched.length && to.path !== '/') {
          this.navigationVisible = true;         
          this.linkComand = this.$store.state.games.find((e) => e.route == to.name).linkTurn;          
        } else {
          this.navigationVisible = false;   
        }
        next()
      })


      let glichNow = 1;
      let time = setInterval(()=>{
        let glichItem = this.$refs['glich'+glichNow];
        if(glichItem){
          glichItem.style.opacity = 1;    
          glichItem.style.left = this.randomInteger(10,90) + '%';
          glichItem.style.top = this.randomInteger(10,90) + '%';    
          // this.glichOff(glichItem);
          glichNow++;
          if(glichNow > 12) glichNow = 1;
        }
        
        
        // this.$refs['glich'+glichNow].style.opacity = 1;
      }, 125)
  }
}
</script>