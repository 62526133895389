<template>
  <div class="navigation">
    <div class="navigation-main">
      <!--<img src="@/assets/img/mob-arrow.png" class="navigation-main__arrow" /> -->
      <div class="navigation-main-left">
        <a v-if="false" href="#trans" class="navigation-main-left__link stream" >
          <span class="navigation-main-left__link__txt">          
            Трансляция финала
          </span>
        </a>
        <a href="#Guid" class="navigation-main-left__link" >
         <span class="navigation-main-left__link__txt">Информация о турнире </span>
        </a>
        <a v-if="false" href="#table" class="navigation-main-left__link" >
          <span class="navigation-main-left__link__txt">Турнирная таблица</span>
        </a>
        <a href="#video" class="navigation-main-left__link" >
          <span class="navigation-main-left__link__txt">Видео</span>
        </a>
        <a href="#Form" class="navigation-main-left__link" >
          <span class="navigation-main-left__link__txt">Стать курьером</span>
        </a>
      </div>
      <div class="navigation-main-right">
        <router-link class="navigation-main-right__item" to="/dota2">
          <span>Dota 2</span>
        </router-link>        
        <router-link class="navigation-main-right__item" to="/warface">
          <span>Warface</span>
        </router-link>
        <router-link class="navigation-main-right__item" to="/fifa">
          <span>FIFA 21</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import style from './style.scss';

export default {
  name: 'Navigation',
  data(){
    return{
      
    }
  },
  components: {
    
  }
}

</script>